import request from '@/utils/request'

export function fetchHisList(data) {
    return request({
        url: '/articleContentHisInfoServ/getArticleHisInfoList',
        method: 'post',
        data: data
    })
}

export function transHisData(data) {
    return request({
        url: '/articleContentHisInfoServ/transHisData',
        method: 'post',
        data: data
    })
}

export function deleteHisArticle(data) {
    return request({
        url: '/articleContentHisInfoServ/deleteHisArticle',
        method: 'post',
        data: data
    })
}
<template>
  <div class="home">
    <banner isHome="true"></banner>
    <div class="site-content animate">
      <!--通知栏-->
      <div class="notify">
        <div class="search-result" v-if="hideSlogan">
          <span v-if="searchWords">搜索结果："{{ searchWords }}" 相关文章</span>
          <span v-else-if="category">分类 "{{ category }}" 相关文章</span>
        </div>
        <quote v-else>{{ notice }}</quote>
      </div>

      <!--焦点图-->
      <div class="top-feature" v-if="!hideSlogan">
        <section-title>
          <div style="display: flex;align-items: flex-end;">聚焦
            <small-ico></small-ico>
          </div>
        </section-title>
        <!--        <el-table-->
        <!--            v-skeleton="{loading: loading, rows: 10}"-->
        <!--            :data="this.postList"-->
        <!--            style="width: 100%"-->
        <!--        >-->
        <!--          <el-table-column-->
        <!--              prop="createTime"-->
        <!--              label="日期"-->
        <!--              width="180"-->
        <!--          />-->
        <!--        </el-table>-->
        <div class="feature-content">
          <div class="feature-item" v-for="item in features" :key="item.id">
            <Feature :data="item"></Feature>
          </div>
        </div>
      </div>
      <!--文章列表-->
      <main class="site-main" :class="{'search':hideSlogan}">
        <section-title v-if="!hideSlogan">推荐</section-title>
        <template v-for="item in postList">
          <post v-if="postList"
                :post="item"
                :key="item.id"></post>
        </template>
      </main>

      <!--加载更多-->
      <div class="more" v-show="hasNextPage">
        <div class="more-btn" @click="loadMore">More</div>
      </div>


      <el-button @click="handleDialog">写文章</el-button>
      <addArticle title="文章管理" v-if="addArticleDialog" ref="addArticle"
                  @closeDialog="this.addArticleDialog = false"/>

      <div v-if="!hideSlogan" style="margin-bottom: 100px">
        <section-title>
          <div style="display: flex;align-items: flex-end;">回收站
            <small-ico></small-ico>
          </div>
        </section-title>

        <el-table :data="articleInfoHisList"
                  height="300px"
                  class="el-table--border">
          <el-table-column prop="articleId" label="文章ID"></el-table-column>
          <el-table-column prop="articleTitle" label="文章标题"></el-table-column>
          <el-table-column prop="articleContent" label="文章内容"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column prop="createTime" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="recover(scope.row)">恢复</el-button>
              <el-button type="text" @click="deleteArticleHis(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/banner'
import Feature from '@/components/feature'
import sectionTitle from '@/components/section-title'
import Post from '@/components/post'
import SmallIco from '@/components/small-ico'
import Quote from '@/components/quote'
import {fetchList} from '../api'
import {deleteHisArticle, fetchHisList, transHisData} from '../api/articleHisInfo.js'
import AddArticle from "@/components/addArticle.vue";

export default {
  name: 'Home',
  props: ['cate', 'words'],
  data() {
    return {
      addArticleDialog: false,
      features: [],
      postList: [],
      articleInfoHisList: [],
      formData: {},
      queryParam: {
        categoryName: "",
        pageSize: 5,
        pageNum: 1
      },
      hasNextPage: false
    }
  },
  components: {
    AddArticle,
    Banner,
    Feature,
    sectionTitle,
    Post,
    SmallIco,
    Quote
  },
  computed: {
    searchWords() {
      return this.$route.params.words
    },
    category() {
      return this.$route.params.cate
    },
    hideSlogan() {
      return this.category || this.searchWords
    },
    notice() {
      return this.$store.getters.notice
    }
  },
  methods: {
    modality(row) {
      let admitPersonNum = row.row.admitPersonNum;
      // 通过id标识来改变当前行的文字颜色
      if (admitPersonNum >= 10 && admitPersonNum < 30) {
        return {
          color: "green"
        };
      }
      if (admitPersonNum >= 30 && admitPersonNum < 50) {
        return {
          color: "orange"
        };
      }
      if (admitPersonNum >= 50) {
        return {
          color: "red"
        };
      }
    },
    // 按钮方法
    handleDialog() {
      this.$message("即将打开编辑组件")
      this.addArticleDialog = true;
      this.$nextTick(() => {
        this.$refs.addArticle.init(2);
      });
    },
    deleteArticleHis(data) {
      this.formData.userId = this.$store.getters.userInfo.userId;
      this.formData.articleId = data.articleId;
      deleteHisArticle(this.formData).then(res => {
        if (res.code === 200) {
          this.fetchHisList()
          this.$message.success("历史数据清理成功！")
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    recover(data) {
      this.formData.userId = this.$store.getters.userInfo.userId;
      this.formData.articleId = data.articleId;
      transHisData(this.formData).then(res => {
        if (res.code === 200) {
          this.$message.success(res.msg)
          this.fetchHisList()
          this.fetchList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    fetchHisList() {
      this.formData.articleId = ""
      this.formData.userId = this.$store.getters.userInfo.userId;
      fetchHisList(this.formData).then(res => {
        if (res.code === 200) {
          this.articleInfoHisList = res.data
        }
      })
    },
    fetchList() {
      // 创建一个 loading 实例并赋值给 loading 变量
      let loading = this.$loading({
        text: "加载中", // 设置 loading 文本为 "加载中"
        spinner: "el-icon-loading", // 使用 Element UI 提供的加载图标
        background: "rgba(0, 0, 0, 0.7)", // 设置 loading 遮罩层背景色为半透明黑色
        target: document.querySelector("body"), // 将 loading 遮罩层挂载到页面 body 元素上
      });
      // this.loading = true
      fetchList(this.queryParam).then(res => {
        this.postList = res.data.items || []
        this.queryParam.pageNum = res.data.currPage
        this.hasNextPage = res.data.hasNextPage
        // 首先，对 res.data.items 进行排序
        res.data.items.sort(function (a, b) {
          // 如果 a 的 viewCounts 小于 b 的 viewCounts，则 a 应该排在 b 之后
          // 反之亦然。为了降序排序，我们需要返回 b.viewCounts - a.viewCounts
          return b.viewCounts - a.viewCounts;
        });
        this.features = res.data.items.slice(0, 3)
      }).catch(err => {
        console.log(err)
      })
      loading.close();
    },
    loadMore() {
      // 创建一个 loading 实例并赋值给 loading 变量
      let loading = this.$loading({
        text: "加载中", // 设置 loading 文本为 "加载中"
        spinner: "el-icon-loading", // 使用 Element UI 提供的加载图标
        background: "rgba(0, 0, 0, 0.7)", // 设置 loading 遮罩层背景色为半透明黑色
        target: document.querySelector("body"), // 将 loading 遮罩层挂载到页面 body 元素上
      });
      this.queryParam.pageNum += 1
      fetchList(this.queryParam).then(res => {
        this.postList = this.postList.concat(res.data.items || [])
        this.queryParam.pageNum = res.data.currPage
        this.hasNextPage = res.data.hasNextPage
        loading.close()
      })
    }
  },
  created() {
    this.fetchList()
    this.fetchHisList()
  },
  mounted() {
    // 获取路由参数中的 cate
    const cate = this.$route.params.cate;
    if (cate) {
      this.queryParam.categoryName = cate
    }
    // this.fetchFocus();
    this.fetchList();
  }
}
</script>
<style scoped lang="less">

.site-content {
  .notify {
    margin: 60px 0;
    border-radius: 3px;

    & > div {
      padding: 20px;
    }
  }


  .search-result {
    padding: 15px 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    border: 1px dashed #ddd;
    color: #828282;
  }
}

.top-feature {
  width: 100%;
  height: auto;
  margin-top: 30px;

  .feature-content {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .feature-item {
      width: 32.9%;
    }
  }
}

.site-main {
  padding-top: 80px;

  &.search {
    padding-top: 0;
  }
}

.more {
  margin: 50px 0;

  .more-btn {
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #ADADAD;
    border: 1px solid #ADADAD;
    border-radius: 20px;
    margin: 0 auto;
    cursor: pointer;

    &:hover {
      color: #8fd0cc;
      border: 1px dashed #8fd0cc;
    }
  }
}

/******/
@media (max-width: 800px) {
  .top-feature {
    display: none;
  }

  .site-main {
    padding-top: 40px;
  }

  .site-content {
    .notify {
      margin: 30px 0 0 0;
    }

    .search-result {
      margin-bottom: 20px;
      font-size: 16px;
    }
  }
}

/******/
</style>
